import useMyPatient from '@/hooks/react-query/useMyPatient';
import { Card } from './components/Card';

export const InsuranceStatusCard = () => {
   const { patient } = useMyPatient();

   if (!patient) {
      return null;
   }

   return (
      <>
         {patient?.insurance?.status === 'pending' && (
            <Card minH="none" bg="background.secondary" mb={8}>
               <Card.Title
                  fontWeight={600}
                  display="flex"
                  gap={3}
                  alignItems="center"
               >
                  We are checking your insurance
               </Card.Title>
               <Card.Text fontSize="lg" pr={4}>
                  Thank you for providing your updated insurance information. We
                  are currently reviewing it, and we’ll notify you once coverage
                  verification is complete. For more information, please contact
                  our support team.
               </Card.Text>
               <Card.ButtonGroup>
                  <Card.ButtonLink href="/support">
                     Contact support
                  </Card.ButtonLink>
               </Card.ButtonGroup>
            </Card>
         )}

         {patient?.insurance?.status === 'invalid' && (
            <Card minH="none" bg="background.secondary" mb={8}>
               <Card.Title
                  fontWeight={600}
                  display="flex"
                  gap={3}
                  alignItems="center"
               >
                  Please update your insurance info
               </Card.Title>
               <Card.Text fontSize="lg" pr={4}>
                  Please give us the details of your insurance coverage so that
                  we can confirm your eligibility and verify that you are
                  covered in order to continue care.
               </Card.Text>
               <Card.ButtonGroup>
                  <Card.ButtonLink href="/support">
                     Contact support
                  </Card.ButtonLink>
               </Card.ButtonGroup>
            </Card>
         )}
      </>
   );
};
