import useMyPatient from './react-query/useMyPatient';
import { PatientInsuranceStatus } from '@innerwell/dtos';

export const useAppointmentCanBeScheduled = () => {
   const { patient } = useMyPatient();

   if (!patient) {
      return false;
   }

   if (
      !patient.insurance ||
      patient.insurance?.status === PatientInsuranceStatus.VALID
   ) {
      return true;
   }

   return false;
};
